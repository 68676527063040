var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page_404"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "contant_box_404"
  }, [_c('h3', {
    staticClass: "h2"
  }, [_vm._v(" Look like you're lost ")]), _c('p', [_vm._v("the page you are looking for not available!")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_vm._v("Go to Dashboard")])], 1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "four_zero_four_bg"
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v("404")])]);

}]

export { render, staticRenderFns }